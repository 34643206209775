// const header = document.querySelector('[data-header]')
const sections = [...document.querySelectorAll('[data-section]')];
const scrollRoot = document.querySelector('[data-scroller]');

const options: {} = {
    root: scrollRoot,
    rootMargin: '0px',
    threshold: 0
}

/* The callback that will fire on intersection */
const onIntersect: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
        console.log(entry.target, entry.isIntersecting);
        if (entry.isIntersecting) {
            entry.target.classList.add('section-active');
        } else {
            entry.target.classList.remove('section-active');
        }

    });
}

/* Create the observer */
const observer = new IntersectionObserver(onIntersect, options);

/* Set our observer to observe each section */
sections.forEach((section) => {
    observer.observe(section);
});









const quotes = [...document.querySelectorAll('[data-quote]')];
const scrollRoot2 = document.querySelector('[data-scroller]');

const options2: {} = {
    root: scrollRoot,
    rootMargin: '-30%',
    threshold: 0
}

/* The callback that will fire on intersection */
const onIntersect2: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
        console.log(entry.target, entry.isIntersecting);
        let target = entry.target.parentElement
        if (entry.isIntersecting) {
            target?.classList.add('quote-active');
        } else {
            target?.classList.remove('quote-active');
        }

    });
}

/* Create the observer */
const observer2 = new IntersectionObserver(onIntersect2, options2);

/* Set our observer to observe each section */
quotes.forEach((quote) => {
    observer2.observe(quote);
});

